// Generated by Framer (e83dae3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/jeIIip8Yb";

const enabledGestures = {W6hCK4r4o: {hover: true}, xVnEazx8s: {hover: true}};

const cycleOrder = ["xVnEazx8s", "W6hCK4r4o"];

const serializationHash = "framer-5cMml"

const variantClassNames = {W6hCK4r4o: "framer-v-ue96ms", xVnEazx8s: "framer-v-s2oe4x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Default: "xVnEazx8s", Inverse: "W6hCK4r4o"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xVnEazx8s", YwyRGS3Ya: title ?? props.YwyRGS3Ya ?? "Button Text"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YwyRGS3Ya, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xVnEazx8s", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-s2oe4x", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"xVnEazx8s"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"W6hCK4r4o-hover": {"data-framer-name": undefined}, "xVnEazx8s-hover": {"data-framer-name": undefined}, W6hCK4r4o: {"data-framer-name": "Inverse"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-swuwne"} data-styles-preset={"jeIIip8Yb"}>Button Text</motion.p></React.Fragment>} className={"framer-1n7hnqa"} layoutDependency={layoutDependency} layoutId={"u54WnCFGt"} text={YwyRGS3Ya} variants={{W6hCK4r4o: {"--extracted-r6o4lv": "var(--token-c5446a54-80de-4f79-b24b-55997bf1fe5e, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({W6hCK4r4o: {children: <React.Fragment><motion.p className={"framer-styles-preset-swuwne"} data-styles-preset={"jeIIip8Yb"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-c5446a54-80de-4f79-b24b-55997bf1fe5e, rgb(255, 255, 255)))"}}>Button Text</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1rc9d97"} data-framer-name={"Button Border"} layoutDependency={layoutDependency} layoutId={"zv0O6wqDD"} style={{backgroundColor: "var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(21, 21, 21))"}} variants={{W6hCK4r4o: {backgroundColor: "var(--token-c5446a54-80de-4f79-b24b-55997bf1fe5e, rgb(255, 255, 255))"}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5cMml.framer-zry1lt, .framer-5cMml .framer-zry1lt { display: block; }", ".framer-5cMml.framer-s2oe4x { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 2px 0px; position: relative; width: min-content; }", ".framer-5cMml .framer-1n7hnqa { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-5cMml .framer-1rc9d97 { bottom: 0px; flex: none; height: 1px; left: 0px; position: absolute; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5cMml.framer-s2oe4x { gap: 0px; } .framer-5cMml.framer-s2oe4x > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-5cMml.framer-s2oe4x > :first-child { margin-top: 0px; } .framer-5cMml.framer-s2oe4x > :last-child { margin-bottom: 0px; } }", ".framer-5cMml.framer-v-s2oe4x.hover .framer-1rc9d97, .framer-5cMml.framer-v-ue96ms.hover .framer-1rc9d97 { width: 20px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19.5
 * @framerIntrinsicWidth 94.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"W6hCK4r4o":{"layout":["auto","auto"]},"MUq7xOso1":{"layout":["auto","auto"]},"xq2Sj0NOH":{"layout":["auto","auto"]}}}
 * @framerVariables {"YwyRGS3Ya":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerQ3kdzq8JL: React.ComponentType<Props> = withCSS(Component, css, "framer-5cMml") as typeof Component;
export default FramerQ3kdzq8JL;

FramerQ3kdzq8JL.displayName = "Button Text";

FramerQ3kdzq8JL.defaultProps = {height: 19.5, width: 94.5};

addPropertyControls(FramerQ3kdzq8JL, {variant: {options: ["xVnEazx8s", "W6hCK4r4o"], optionTitles: ["Default", "Inverse"], title: "Variant", type: ControlType.Enum}, YwyRGS3Ya: {defaultValue: "Button Text", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerQ3kdzq8JL, [...sharedStyle.fonts])